<template>
  <div class="attachments">
    <Header back="返回" title="合作项目" index="首页" titleOne="合同管理" titleTwo="合作项目" beforeTitle="详情" />
    <div class="content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="项目详情" name="first">
          <h1>项目信息</h1>
          <el-form :model="projectform" label-width="80px" :label-position="labelPosition">
            <ul>
              <li>
                <span>企业名称</span>
                <p>{{projectform.company_name ?projectform.company_name :'-'}}</p>
              </li>
              <li>
                <span>合同名称</span>
                <p>{{projectform.contract_name ?projectform.contract_name :'-'}}</p>
              </li>
              <li>
                <span>项目名称</span>
                <p>{{projectform.product_name?projectform.product_name:'-' }}</p>
              </li>
              <li>
                <span>项目类型</span>
                <p>{{projectform.project_type?projectform.project_type:'-' }}</p>
              </li>
              <li>
                <span>项目编号</span>
                <p>{{projectform.code?projectform.code:'-' }}</p>
              </li>
              <li>
                <span>渠道商</span>
                <p>{{projectform.channel ?projectform.channel :'-'}}</p>
              </li>
              <li>
                <span>所属区域</span>
                <p>{{projectform.area ?projectform.area :'-'}}</p>
              </li>
              <li>
                <span>状态</span>
                <p v-if="!projectform.status">-</p>
                <p v-if="projectform.status == 0">未开展</p>
                <p v-if="projectform.status == 1">已流转</p>
                <p v-if="projectform.status == 2">已分配</p>
                <p v-if="projectform.status == 3">开展中</p>
                <p v-if="projectform.status == 4">已完结</p>
              </li>
              <li>
                <span>计划提报</span>
                <p>{{ projectform.submit_time?projectform.submit_time:'-' }}</p>
              </li>
              <li>
                <span>计划批次</span>
                <p>{{ projectform.batch? projectform.batch:'-'}}</p>
              </li>
              <li>
                <span>项目备注</span>
                <p>{{ projectform.explain?projectform.explain:'-'}}</p>
              </li>
              <li>
                <span>付款说明</span>
                <p>{{ projectform.payment?projectform.payment:'-'}}</p>
              </li>
            </ul>
            <h1 class="detail_h1">收款模式</h1>
            <ul>
              <li>
                <span>收费方式</span>
                <p style="display:block" v-if="!projectform.billing_method">-</p>
                <p style="display:block" v-if="projectform.billing_method == 1">固定</p>
                <p style="display:block" v-if="projectform.billing_method == 2">计提</p>
              </li>
              <li>
                <span>总金额（元）</span>
                <p>{{projectform.total_money?projectform.total_money:'-'}}</p>
              </li>
              <li>
                <span>定金（元）</span>
                <p>{{projectform.deposit?projectform.deposit:'-'}}</p>
              </li>
              <li>
                <span>提交（元）</span>
                <p>{{projectform.submit_money?projectform.submit_money:'-'}}</p>
              </li>
              <li>
                <span>通过（元）</span>
                <p>{{projectform.pass_money?projectform.pass_money:'-'}}</p>
              </li>
              <li>
                <span>资金下达（元）</span>
                <p>{{projectform.release_money?projectform.release_money:'-'}}</p>
              </li>
              <li>
                <span>验收确认（元）</span>
                <p>{{projectform.check_money?projectform.check_money:'-'}}</p>
              </li>
            </ul>
            <h1 class="detail_h1">项目参与人员（内部）</h1>
            <el-table
              :data="projectform.participants.inside"
              style="width: 100%"
              :key="Math.random()"
            >
              <el-table-column label="姓名">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.name"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="职务">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.job"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="手机/微信">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.mobile"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="邮箱">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.email"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="联系地址">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.address"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="备注">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.remark"></el-input>
                </template>
              </el-table-column>
            </el-table>
            <h1 class="detail_h1">项目参与人员（客户）</h1>
            <el-table
              :data="projectform.participants.custom"
              style="width: 100%"
              :key="Math.random()"
            >
              <el-table-column label="姓名">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.name"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="职务">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.job"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="手机/微信">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.mobile"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="邮箱">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.email"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="联系地址">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.address"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="备注">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.remark"></el-input>
                </template>
              </el-table-column>
            </el-table>
            <h1 class="detail_h1">项目参与人员（政府）</h1>
            <el-table
              :data="projectform.participants.government"
              style="width: 100%"
              :key="Math.random()"
            >
              <el-table-column label="姓名">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.name"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="职务">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.job"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="手机/微信">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.mobile"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="邮箱">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.email"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="联系地址">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.address"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="备注">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.remark"></el-input>
                </template>
              </el-table-column>
            </el-table>
            <h1 class="detail_h1">项目参与人员（第三方）</h1>
            <el-table
              :data="projectform.participants.third_party"
              style="width: 100%"
              :key="Math.random()"
            >
              <el-table-column label="姓名">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.name"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="职务">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.job"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="手机/微信">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.mobile"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="邮箱">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.email"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="联系地址">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.address"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="备注">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.remark"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="流转路径" name="second">
          <h1 style="margin:30px 0">流转路径</h1>
          <div class="block">
            <el-timeline>
              <el-timeline-item
                v-for="(item,index) in historyList"
                :key="index"
                :timestamp="item.update_time"
                placement="top"
              >
                <el-card>
                  <div style="margin: auto 0;">
                    <h4 style="font-size: 16px;font-weight: bold;margin: 5px 0;">{{ item.operate }}</h4>
                    <p>{{ item.receiver_name + ' ' + item.update_time + ' ' + item.operate + '于 ' + item.operator_name }}</p>
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      activeName: 'first',
      labelPosition: "top",
      projectform: {
        submit_time: "",
        batch: "",
        explain: "",
        payment: "",
        billing_method: "",
        total_money: "",
        deposit: "",
        submit_money: "",
        pass_money: "",
        release_money: "",
        check_money: "",
        participants: {
          inside: [],
          custom: [],
          government: [],
          third_party: [],
        },
      },
      historyList: [],
    };
  },
  created () {
    if (this.$route.query.id) {
      this.handleClick()
    }
  },
  methods: {
    getDetail () {
      this.axios
        .get("/api/contract/view_projcet", {
          params: {
            id: this.$route.query.id,
          },
        })
        .then((res) => {
          this.projectform = res.data;
        });
    },
    getHistory () {
      this.axios.get('/api/contract/flow_history', {
        params: {
          id: this.$route.query.id,
        }
      }).then((res) => {
        this.historyList = res.data
      })
    },
    handleClick () {
      if (this.activeName == 'first') {
        this.getDetail()
      } else if (this.activeName == 'second') {
        this.getHistory()
      }
    },
  },
};
</script>

<style scoped="scoped">
.attachments ul li {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.attachments ul li span {
  color: #404040;
  margin-right: 20px;
  display: inline-block;
  width: 100px;
  /* float: left; */
}

.attachments ul li p {
  display: inline-block;
  width: 80%;
  margin: 0;
  color: #909399;
}

::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}
.el-form {
  margin-top: 20px;
}
.el-select {
  width: 100%;
}

.upload-demo {
  display: inline-block;
}

.ChooseFile {
  width: 224px;
  height: 40px;
  background: #edeef4 !important;
  color: #909399 !important;
  border: none;
}

.UploadFile {
  width: 88px;
  height: 40px;
  background: #0f38ff !important;
  color: #fff !important;
  border: none;
  margin-left: 20px;
}

.el-upload-list {
  display: inline-block;
}

.el-upload-list__item-name {
  margin-top: 20px;
}

::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.ImportTop span {
  color: #a6abc7;
}
.detail_h1 {
  margin-top: 30px;
}
div/deep/.el-timeline-item__wrapper {
  width: 100%;
}
div/deep/.el-card__body {
  height: 70px;
}
div/deep/.el-timeline-item {
  margin-top: 0 !important;
}
</style>
